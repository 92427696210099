/* eslint-disable */
import { noExponent } from '../utils/numbers';
import { omitDefault } from "@cosmjs/amino";

export const customAminoTypes = {
    '/ibc.applications.transfer.v1.MsgTransfer': {
        aminoType: 'cosmos-sdk/MsgTransfer',
        toAmino: ({
            source_port,
            source_channel,
            token,
            sender,
            receiver,
            timeout_height,
            timeout_timestamp,
        }) => {
            const obj = {
                source_port: source_port,
                source_channel: source_channel,
                token: token,
                sender: sender,
                receiver: receiver,
                timeout_height: timeout_height
                    ? {
                        revision_height: omitDefault(timeout_height.revision_height)?.toString(),
                        revision_number: omitDefault(timeout_height.revision_height)?.toString(),
                    }
                    : {},
            }

            if (timeout_timestamp) {
                obj.timeout_timestamp = omitDefault(timeout_timestamp)?.toString();
            }

            return obj;
        },
        fromAmino: ({
            source_port,
            source_channel,
            token,
            sender,
            receiver,
            timeout_height,
            timeout_timestamp,
        }) => ({
            sourcePort: source_port,
            sourceChannel: source_channel,
            token: token,
            sender: sender,
            receiver: receiver,
            timeoutHeight: timeout_height
                ? {
                    revisionHeight: BigInt(timeout_height.revision_height || "0"),
                    revisionNumber: BigInt(timeout_height.revision_number || "0"),
                }
                : undefined,
            timeoutTimestamp: BigInt(timeout_timestamp || "0"),
        }),
    },
    '/cosmos.bank.v1beta1.MsgSend': {
        aminoType: 'cosmos-sdk/MsgSend',
        toAmino: ({
            fromAddress,
            toAddress,
            amount,
        }) => ({
            from_address: fromAddress,
            to_address: toAddress,
            amount: [...amount],
        }),
        fromAmino: ({
            from_address,
            to_address,
            amount,
        }) => ({
            fromAddress: from_address,
            toAddress: to_address,
            amount: [...amount],
        }),
    },
    '/OmniFlix.onft.v1beta1.MsgCreateDenom': {
        aminoType: 'OmniFlix/onft/MsgCreateDenom',
        toAmino: ({
            id,
            symbol,
            name,
            description,
            preview_uri,
            schema,
            sender,
            creation_fee,
        }) => {
            const obj = {
                id,
                symbol,
                sender,
                creation_fee,
            };

            if (name) {
                obj.name = name;
            }
            if (description) {
                obj.description = description;
            }
            if (preview_uri || preview_uri === '') {
                obj.preview_uri = preview_uri;
            }
            if (schema) {
                obj.schema = schema;
            }

            return obj;
        },
        fromAmino: ({
            id,
            symbol,
            name,
            description,
            preview_uri,
            schema,
            sender,
            creation_fee,
        }) => {
            const obj = {
                id,
                symbol,
                sender,
                creationFee: creation_fee,
            };

            if (name) {
                obj.name = name;
            }
            if (description) {
                obj.description = description;
            }
            if (preview_uri) {
                obj.previewUri = preview_uri;
            }
            if (schema) {
                obj.schema = schema;
            }

            return obj;
        },
    },
    '/OmniFlix.onft.v1beta1.MsgUpdateDenom': {
        aminoType: 'OmniFlix/onft/MsgUpdateDenom',
        toAmino: ({
            id,
            name,
            description,
            preview_uri,
            sender,
        }) => {
            const obj = {
                id,
                sender,
            };

            if (name) {
                obj.name = name;
            }
            if (description) {
                obj.description = description;
            }
            if (preview_uri || preview_uri === '') {
                obj.preview_uri = preview_uri;
            }

            return obj;
        },
        fromAmino: ({
            id,
            name,
            description,
            preview_uri,
            sender,
        }) => {
            const obj = {
                id,
                sender,
            };

            if (name) {
                obj.name = name;
            }
            if (description) {
                obj.description = description;
            }
            if (preview_uri) {
                obj.previewUri = preview_uri;
            }

            return obj;
        },
    },
    '/OmniFlix.onft.v1beta1.MsgTransferDenom': {
        aminoType: 'OmniFlix/onft/MsgTransferDenom',
        toAmino: ({
            id,
            sender,
            recipient,
        }) => {
            return {
                id,
                sender,
                recipient,
            };
        },
        fromAmino: ({
            id,
            sender,
            recipient,
        }) => {
            return {
                id,
                sender,
                recipient,
            };
        },
    },
    '/OmniFlix.onft.v1beta1.MsgMintONFT': {
        aminoType: 'OmniFlix/onft/MsgMintONFT',
        toAmino: ({
            id,
            denom_id,
            metadata,
            data,
            transferable,
            extensible,
            nsfw,
            royalty_share,
            sender,
            recipient,
        }) => {
            const obj = {
                id,
                denom_id,
                metadata: metadata || {},
                data: data || {},
                royalty_share: royalty_share || '0.000000000000000000',
                sender,
                recipient,
            };

            if (transferable) {
                obj.transferable = transferable;
            }
            if (extensible) {
                obj.extensible = extensible;
            }
            if (nsfw) {
                obj.nsfw = nsfw;
            }
            if (metadata) {
                const data = {};
                if (metadata.name) {
                    data.name = metadata.name;
                }
                if (metadata.description) {
                    data.description = metadata.description;
                }
                if (metadata.preview_uri) {
                    data.preview_uri = metadata.preview_uri;
                }
                if (metadata.media_uri) {
                    data.media_uri = metadata.media_uri;
                }

                obj.metadata = data;
            }

            return obj;
        },
        fromAmino: ({
            id,
            denom_id,
            metadata,
            data,
            transferable,
            extensible,
            nsfw,
            royalty_share,
            sender,
            recipient,
        }) => {
            const obj = {
                id,
                denomId: denom_id,
                metadata: metadata || {},
                data: data || {},
                sender,
                recipient,
            };

            if (royalty_share) {
                let royalty = String(Number(royalty_share) * (10 ** 18));
                if (royalty.length && royalty.length === 18) {
                    royalty = royalty.substring(0, 4) + '00000000000000';
                } else if (royalty.length && royalty.length === 17) {
                    royalty = royalty.substring(0, 4) + '0000000000000';
                }
                obj.royaltyShare = String(royalty);
            } else {
                obj.royaltyShare = '0';
            }
            if (transferable) {
                obj.transferable = transferable;
            }
            if (extensible) {
                obj.extensible = extensible;
            }
            if (nsfw) {
                obj.nsfw = nsfw;
            }
            if (metadata) {
                const data = {};
                if (metadata.name) {
                    data.name = metadata.name;
                }
                if (metadata.description) {
                    data.description = metadata.description;
                }
                if (metadata.preview_uri) {
                    data.previewUri = metadata.preview_uri;
                }
                if (metadata.media_uri) {
                    data.mediaUri = metadata.media_uri;
                }

                obj.metadata = data;
            }

            return obj;
        },
    },
    '/OmniFlix.onft.v1beta1.MsgTransferONFT': {
        aminoType: 'OmniFlix/onft/MsgTransferONFT',
        toAmino: ({
            id,
            denom_id,
            sender,
            recipient,
        }) => {
            return {
                id,
                denom_id,
                sender,
                recipient,
            };
        },
        fromAmino: ({
            id,
            denom_id,
            sender,
            recipient,
        }) => {
            return {
                id,
                denomId: denom_id,
                sender,
                recipient,
            };
        },
    },
    '/OmniFlix.onft.v1beta1.MsgBurnONFT': {
        aminoType: 'OmniFlix/onft/MsgBurnONFT',
        toAmino: ({
            id,
            denom_id,
            sender,
        }) => {
            return {
                id,
                denom_id,
                sender,
            };
        },
        fromAmino: ({
            id,
            denom_id,
            sender,
        }) => {
            return {
                id,
                denomId: denom_id,
                sender,
            };
        },
    },
    '/OmniFlix.marketplace.v1beta1.MsgListNFT': {
        aminoType: 'OmniFlix/marketplace/MsgListNFT',
        toAmino: ({
            id,
            nft_id,
            denom_id,
            price,
            owner,
            split_shares,
        }) => {
            return {
                id,
                nft_id,
                denom_id,
                price: price || undefined,
                owner,
                split_shares: split_shares || null,
            };
        },
        fromAmino: ({
            id,
            nft_id,
            denom_id,
            price,
            owner,
            split_shares,
        }) => {
            const obj = {
                id: id,
                nftId: nft_id,
                denomId: denom_id,
                price,
                owner,
                splitShares: split_shares || [],
            };

            if (split_shares && split_shares.length) {
                const array = [];
                split_shares.map((val) => {
                    let weight = String(Number(val.weight) * (10 ** 18));
                    if (weight.length && weight.length === 18) {
                        weight = weight.substring(0, 4) + '00000000000000';
                    } else if (weight.length && weight.length === 17) {
                        weight = weight.substring(0, 4) + '0000000000000';
                    }

                    array.push({
                        address: val.address,
                        weight: String(weight),
                    });
                });

                obj.splitShares = array;
            }

            return obj;
        },
    },
    '/OmniFlix.marketplace.v1beta1.MsgEditListing': {
        aminoType: 'OmniFlix/marketplace/MsgEditListing',
        toAmino: ({
            id,
            price,
            owner,
        }) => {
            return {
                id,
                price: price || undefined,
                owner,
            };
        },
        fromAmino: ({
            id,
            price,
            owner,
        }) => {
            return {
                id,
                price: price || undefined,
                owner,
            };
        },
    },
    '/OmniFlix.marketplace.v1beta1.MsgDeListNFT': {
        aminoType: 'OmniFlix/marketplace/MsgDeListNFT',
        toAmino: ({
            id,
            owner,
        }) => {
            return {
                id,
                owner,
            };
        },
        fromAmino: ({
            id,
            owner,
        }) => {
            return {
                id,
                owner,
            };
        },
    },
    '/OmniFlix.marketplace.v1beta1.MsgBuyNFT': {
        aminoType: 'OmniFlix/marketplace/MsgBuyNFT',
        toAmino: ({
            id,
            price,
            buyer,
        }) => {
            return {
                id,
                price: price || undefined,
                buyer,
            };
        },
        fromAmino: ({
            id,
            price,
            buyer,
        }) => {
            return {
                id,
                price: price || undefined,
                buyer,
            };
        },
    },
    '/OmniFlix.marketplace.v1beta1.MsgCreateAuction': {
        aminoType: 'OmniFlix/marketplace/MsgCreateAuction',
        toAmino: ({
            nft_id,
            denom_id,
            start_time,
            start_price,
            duration,
            increment_percentage,
            whitelist_accounts,
            split_shares,
            owner,
        }) => {
            const obj = {
                nft_id,
                denom_id,
                start_time: start_time || undefined,
                start_price: start_price || undefined,
                increment_percentage: increment_percentage || '0.000000000000000000',
                split_shares: split_shares || null,
                owner,
            };

            if (duration) {
                obj.duration = duration;
            }
            if (whitelist_accounts) {
                obj.whitelist_accounts = whitelist_accounts;
            }

            return obj;
        },
        fromAmino: ({
            nft_id,
            denom_id,
            start_time,
            start_price,
            duration,
            increment_percentage,
            whitelist_accounts,
            split_shares,
            owner,
        }) => {
            const obj = {
                nftId: nft_id,
                denomId: denom_id,
                startTime: start_time || undefined,
                startPrice: start_price || undefined,
                splitShares: split_shares || [],
                owner,
            };

            if (increment_percentage) {
                let percentage = String((10 ** 18) * Number(increment_percentage));
                if (percentage.length && percentage.length === 18) {
                    percentage = percentage.substring(0, 4) + '00000000000000';
                } else if (percentage.length && percentage.length === 17) {
                    percentage = percentage.substring(0, 4) + '0000000000000';
                }

                obj.incrementPercentage = percentage;
            } else {
                obj.incrementPercentage = '0';
            }
            if (duration) {
                const data = {
                    seconds: 0,
                };
                let duration1 = duration;
                duration1 = duration1 / (10 ** 9);
                duration1 = noExponent(duration1);
                data.seconds = duration1;
                obj.duration = data;
            }
            if (whitelist_accounts) {
                obj.whitelistAccounts = whitelist_accounts;
            }
            if (split_shares && split_shares.length) {
                const array = [];
                split_shares.map((val) => {
                    let weight = String(Number(val.weight) * (10 ** 18));
                    if (weight.length && weight.length === 18) {
                        weight = weight.substring(0, 4) + '00000000000000';
                    } else if (weight.length && weight.length === 17) {
                        weight = weight.substring(0, 4) + '0000000000000';
                    }

                    array.push({
                        address: val.address,
                        weight: String(weight),
                    });
                });

                obj.splitShares = array;
            }

            return obj;
        },
    },
    '/OmniFlix.marketplace.v1beta1.MsgCancelAuction': {
        aminoType: 'OmniFlix/marketplace/MsgCancelAuction',
        toAmino: ({
            auction_id,
            owner,
        }) => {
            return {
                auction_id,
                owner,
            };
        },
        fromAmino: ({
            auction_id,
            owner,
        }) => {
            return {
                auctionId: auction_id,
                owner,
            };
        },
    },
    '/OmniFlix.marketplace.v1beta1.MsgPlaceBid': {
        aminoType: 'OmniFlix/marketplace/MsgPlaceBid',
        toAmino: ({
            auction_id,
            amount,
            bidder,
        }) => {
            return {
                auction_id,
                amount: amount || undefined,
                bidder,
            };
        },
        fromAmino: ({
            auction_id,
            amount,
            bidder,
        }) => {
            return {
                auctionId: auction_id,
                amount: amount || undefined,
                bidder,
            };
        },
    },
    '/OmniFlix.itc.v1.MsgCreateCampaign': {
        aminoType: 'OmniFlix/itc/MsgCreateCampaign',
        toAmino: ({
            name,
            description,
            interaction,
            claim_type,
            nft_denom_id,
            tokens_per_claim,
            max_allowed_claims,
            deposit,
            nft_mint_details,
            start_time,
            duration,
            distribution,
            creator,
            creation_fee,
        }) => {
            const obj = {
                name,
                description: description || '',
                nft_denom_id: nft_denom_id || '',
                start_time: start_time || undefined,
                creator: creator || '',
                creation_fee: creation_fee || undefined,
            };

            if (claim_type) {
                obj.claim_type = claim_type;
            }
            if (interaction) {
                obj.interaction = interaction;
            }
            if (duration) {
                obj.duration = duration;
            }
            if (tokens_per_claim) {
                obj.tokens_per_claim = tokens_per_claim;
            }
            if (max_allowed_claims) {
                obj.max_allowed_claims = max_allowed_claims;
            }
            if (deposit) {
                obj.deposit = deposit;
            }
            if (nft_mint_details) {
                const data = {};
                if (nft_mint_details.name) {
                    data.name = nft_mint_details.name;
                }
                if (nft_mint_details.denom_id) {
                    data.denom_id = nft_mint_details.denom_id;
                }
                if (nft_mint_details.description) {
                    data.description = nft_mint_details.description;
                }
                if (nft_mint_details.media_uri) {
                    data.media_uri = nft_mint_details.media_uri;
                }
                if (nft_mint_details.preview_uri) {
                    data.preview_uri = nft_mint_details.preview_uri;
                }
                if (nft_mint_details.data) {
                    data.data = nft_mint_details.data;
                }
                if (nft_mint_details.royalty_share) {
                    data.royalty_share = nft_mint_details.royalty_share;
                }
                if (nft_mint_details.nsfw) {
                    data.nsfw = nft_mint_details.nsfw;
                }
                if (nft_mint_details.extensible) {
                    data.extensible = nft_mint_details.extensible;
                }
                if (nft_mint_details.transferable) {
                    data.transferable = nft_mint_details.transferable;
                }

                obj.nft_mint_details = data;
            }
            if (distribution) {
                const data = {};
                if (distribution.type) {
                    data.type = distribution.type;
                }
                if (distribution.stream_duration) {
                    data.stream_duration = distribution.stream_duration;
                }

                obj.distribution = data;
            }

            return obj;
        },
        fromAmino: ({
            name,
            description,
            interaction,
            claim_type,
            nft_denom_id,
            tokens_per_claim,
            max_allowed_claims,
            deposit,
            nft_mint_details,
            start_time,
            duration,
            distribution,
            creator,
            creation_fee,
        }) => {
            const obj = {
                name,
                description: description || '',
                nftDenomId: nft_denom_id || '',
                startTime: start_time || undefined,
                creator: creator || '',
                creationFee: creation_fee || undefined,
            };

            if (claim_type) {
                obj.claimType = claim_type;
            }
            if (interaction) {
                obj.interaction = interaction;
            }
            if (duration) {
                const data = {
                    seconds: 0,
                };
                let duration1 = duration;
                duration1 = duration1 / (10 ** 9);
                duration1 = noExponent(duration1);
                data.seconds = duration1;
                obj.duration = data;
            }
            if (tokens_per_claim) {
                obj.tokensPerClaim = tokens_per_claim;
            }
            if (max_allowed_claims) {
                obj.maxAllowedClaims = max_allowed_claims;
            }
            if (deposit) {
                obj.deposit = deposit;
            }
            if (nft_mint_details) {
                const data = {};
                if (nft_mint_details.name) {
                    data.name = nft_mint_details.name;
                }
                if (nft_mint_details.denom_id) {
                    data.denomId = nft_mint_details.denom_id;
                }
                if (nft_mint_details.description) {
                    data.description = nft_mint_details.description;
                }
                if (nft_mint_details.media_uri) {
                    data.mediaUri = nft_mint_details.media_uri;
                }
                if (nft_mint_details.preview_uri) {
                    data.previewUri = nft_mint_details.preview_uri;
                }
                if (nft_mint_details.data) {
                    data.data = nft_mint_details.data;
                }
                if (nft_mint_details.royalty_share) {
                    data.royaltyShare = String(Number(nft_mint_details.royalty_share) * (10 ** 18));
                } else {
                    data.royaltyShare = '0';
                }
                if (nft_mint_details.nsfw) {
                    data.nsfw = nft_mint_details.nsfw;
                }
                if (nft_mint_details.extensible) {
                    data.extensible = nft_mint_details.extensible;
                }
                if (nft_mint_details.transferable) {
                    data.transferable = nft_mint_details.transferable;
                }

                obj.nftMintDetails = data;
            }
            if (distribution) {
                const data = {
                    streamDuration: {
                        seconds: 0,
                    },
                };

                if (distribution.type) {
                    data.type = distribution.type;
                }
                if (distribution.stream_duration) {
                    let duration = Number(distribution.stream_duration) / (10 ** 9);
                    duration = noExponent(duration);
                    data.streamDuration.seconds = duration;
                } else {
                    data.streamDuration.seconds = 0;
                }

                obj.distribution = data;
            }

            return obj;
        },
    },
    '/OmniFlix.itc.v1.MsgCancelCampaign': {
        aminoType: 'OmniFlix/itc/MsgCancelCampaign',
        toAmino: ({
            campaign_id,
            creator,
        }) => {
            return {
                campaign_id,
                creator,
            };
        },
        fromAmino: ({
            campaign_id,
            creator,
        }) => {
            return {
                campaignId: campaign_id,
                creator,
            };
        },
    },
    '/OmniFlix.itc.v1.MsgClaim': {
        aminoType: 'OmniFlix/itc/MsgClaim',
        toAmino: ({
            campaign_id,
            nft_id,
            interaction,
            claimer,
        }) => {
            const obj = {
                campaign_id,
                nft_id,
                claimer,
            }
            if (interaction) {
                obj.interaction = interaction;
            }

            return obj;
        },
        fromAmino: ({
            campaign_id,
            nft_id,
            interaction,
            claimer,
        }) => {
            const obj = {
                campaignId: campaign_id,
                nftId: nft_id,
                claimer,
            }
            if (interaction) {
                obj.interaction = interaction;
            }

            return obj;
        },
    },
    '/OmniFlix.itc.v1.MsgDepositCampaign': {
        aminoType: 'OmniFlix/itc/MsgDepositCampaign',
        toAmino: ({
            campaign_id,
            amount,
            depositor,
        }) => {
            return {
                campaign_id,
                amount: amount || undefined,
                depositor,
            };
        },
        fromAmino: ({
            campaign_id,
            amount,
            depositor,
        }) => {
            return {
                campaignId: campaign_id,
                amount: amount || undefined,
                depositor,
            };
        },
    },
    '/OmniFlix.streampay.v1.MsgStreamSend': {
        aminoType: 'OmniFlix/streampay/MsgStreamSend',
        toAmino: ({
            sender,
            recipient,
            amount,
            duration,
            stream_type,
            periods,
            cancellable,
            fee,
        }) => {
            const obj = {
                sender,
                recipient,
                amount: amount || undefined,
                cancellable: cancellable || false,
                fee: fee || undefined,
            };

            if (duration) {
                obj.duration = duration;
            }
            if (stream_type) {
                obj.stream_type = stream_type;
            }
            if (periods) {
                obj.periods = periods;
            }

            return obj;
        },
        fromAmino: ({
            sender,
            recipient,
            amount,
            duration,
            stream_type,
            periods,
            cancellable,
            fee,
        }) => {
            const obj = {
                sender,
                recipient,
                amount: amount || undefined,
                cancellable: cancellable || false,
                fee: fee || undefined,
            };

            if (duration) {
                obj.duration = duration;
            }
            if (stream_type) {
                obj.streamType = stream_type;
            }
            if (periods) {
                obj.periods = periods;
            }

            return obj;
        },
    },
    '/OmniFlix.streampay.v1.MsgStopStream': {
        aminoType: 'OmniFlix/streampay/MsgStopStream',
        toAmino: ({
            stream_id,
            sender,
        }) => {
            return {
                stream_id,
                sender,
            };
        },
        fromAmino: ({
            stream_id,
            sender,
        }) => {
            return {
                streamId: stream_id,
                sender,
            };
        },
    },
    '/OmniFlix.streampay.v1.MsgClaimStreamedAmount': {
        aminoType: 'OmniFlix/streampay/MsgClaimStreamedAmount',
        toAmino: ({
            stream_id,
            claimer,
        }) => {
            return {
                stream_id,
                claimer,
            };
        },
        fromAmino: ({
            stream_id,
            claimer,
        }) => {
            return {
                streamId: stream_id,
                claimer,
            };
        },
    },
};
